<template>
  <div>
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section">
        <form @submit="checkForm"
              action=""
              class="form-horizontal"
              method="post"
              novalidate="true">
          <div class="row">
            <div class="form-group">
              <label for="password" class="col-md-4 control-label">{{$t('password')}}</label>

              <div class="col-md-6">
                <input id="password" ref="password" type="password" class="form-control"
                       v-bind:class="{ 'has-errors': errors.password }" name="password" v-model="password" required>
                <span v-if="errors.password" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.password }}</span>
                                    </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label for="confirmPassword" class="col-md-4 control-label">{{$t('confirm_password')}}</label>

              <div class="col-md-6">
                <input id="confirmPassword" ref="confirmPassword" type="password" class="form-control"
                       v-bind:class="{ 'has-errors': errors.confirmPassword }" name="emailAddress"
                       v-model="confirmPassword" required>
                <span v-if="errors.confirmPassword" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.confirmPassword }}</span>
                                    </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="col-md-6">
                <span v-if="errors.resetPassError" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.resetPassError }}</span>
                                    </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="col-md-6 col-md-offset-4">
                <input type="submit" class="button button--primary" :value="$t('reset_password')">
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'user.setnew-password', 'type': 'user'}})
    this.axios.apiCustomer.checkTokenResetPassowrd({'token': this.$route.params.token}, respone => {
    }, error => {
      if (!error.status) {
        this.$router.push({'name': 'error-404'})
      } else {
        let formInfo = {
          id: 'setnew-password-form',
          name: 'setnew-password-form',
          category: 'setnew-password',
          url: window.location.href
        }
        this.eventTrackingFormLoad(formInfo)
      }
    })
  },
  components: {},
  props: {},
  computed: {},
  watch: {
    password: function (newValue, oldValue) {
      if (!this.password) {
        this.errors.password = this.$t('validate_password')
      } else if (this.password.length < 6) {
        this.errors.password = this.$t('validate_invalid_password')
      } else {
        this.errors.password = false
      }
    },
    confirmPassword: function (newValue, oldValue) {
      if (!this.confirmPassword) {
        this.errors.confirmPassword = this.$t('validate_invalid_repassword')
      } else if (this.confirmPassword !== this.password) {
        this.errors.confirmPassword = this.$t('validate_repassword')
      } else {
        this.errors.confirmPassword = false
      }
    }
  },
  data () {
    return {
      confirmPassword: '',
      password: '',
      errors: {
        'confirmPassword': '',
        'password': '',
        'resetPassError': ''
      }
    }
  },
  methods: {
    checkForm: function (e) {
      this.errors = {
        'confirmPassword': '',
        'password': ''
      }
      let data = {
        'token': this.$route.params.token,
        'password': this.password
      }
      let flag = false
      if (!this.password) {
        this.errors.password = 'Password required.'
        this.$refs.password.focus()
        flag = true
      } else if (this.password.length < 6) {
        this.errors.password = this.$t('validate_invalid_password')
        this.$refs.password.focus()
        flag = true
      } else {
        this.errors.password = false
      }

      if (!this.confirmPassword) {
        this.errors.confirmPassword = 'Confirm password required.'
        if (!flag) {
          this.$refs.confirmPassword.focus()
        }
        flag = true
      } else if (this.confirmPassword !== this.password) {
        this.errors.confirmPassword = 'Password and Confirm password don\'t match.'
        if (!flag) {
          this.$refs.confirmPassword.focus()
        }
        flag = true
      } else {
        this.errors.confirmPassword = false
      }
      if (!flag) {
        // reset password
        this.eventTrackingFormSubmit({id: 'setnew-password-form'})
        this.axios.apiCustomer.resetPassword(data, respone => {
          this.eventTrackingFormSuccess({id: 'setnew-password-form'})
          if (respone.data.status) {
            this.$router.push({'name': 'login'})
          }
        }, error => {
          error = error.error
          let formInfo = {
            id: 'setnew-password-form',
            'error': {
              'code': error.code,
              'type': 'validation',
              'error': error.message
            }
          }
          vm.eventTrackingFormError(formInfo)
          this.errors.resetPassError = 'Error !'
        })
      }
      e.preventDefault()
    }
  }
}
</script>
